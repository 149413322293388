'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.journals', {
                    url: '/journals?journals_topic&&journals_full_text&&search_id&&note_id&&saved_tab_id&&otherPage&&savedTabFromOtherPage',
                    templateUrl: 'app/journals/journals.html',
                    controller: 'JournalsCtrl'
                });
        }
    ]);